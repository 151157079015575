<template>
    <b-card no-body class="p-4">
        <b-form @submit.prevent="saveData">
            <b-card-body class="d-flex flex-column" style="row-gap: 12px">
                <b-row>
                    <b-col sm="3" class="">
                        <label for="cus4">Reason Type</label>
                        <b-form-radio-group
                            v-model="data.reasonTypeId"
                            :options="customersTypes"
                            class="d-flex"
                            value-field="id"
                            text-field="name"
                            required
                        ></b-form-radio-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="4">
                        <label for="comp4">Business Name* </label>
                        <b-form-input size="sm" type="text" placeholder="Business name here" required></b-form-input>
                    </b-col>
                    <b-col sm="4">
                        <label for="comp4">Doing Business Name* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            placeholder="Doing Business Name Here"
                            required
                        ></b-form-input>
                    </b-col>
                    <b-col sm="4">
                        <label for="comp4">Business ID/Chamber of Commerce </label>
                        <b-form-input size="sm" type="text" placeholder="Business legal name here"></b-form-input>
                    </b-col>
                    <b-col sm="4">
                        <label for="comp4">Business Address* </label>
                        <b-form-input
                            size="sm"
                            type="text"
                            placeholder="Address here"
                            v-model="data.address2"
                            required
                        ></b-form-input>
                    </b-col>

                    <b-col sm="4">
                        <label for="fname4">Business Phone</label>
                        <b-form-input
                            size="sm"
                            type="tel"
                            placeholder="Phone number here"
                            v-model="data.phone2"
                        ></b-form-input>
                    </b-col>
                </b-row>

                <h4 class="mt-4">Contact Person Information</h4>

                <b-row style="row-gap: 12px">
                    <b-col sm="4">
                        <label>Full Name</label>
                        <b-form-input
                            size="sm"
                            id="title4"
                            type="text"
                            minLength="8"
                            maxLength="8"
                            placeholder="Full name here"
                            required
                        ></b-form-input>

                        <label for="fname4">Phone 1</label>
                        <b-form-input
                            size="sm"
                            type="tel"
                            placeholder="Phone number here"
                            v-model="data.phone1"
                            required
                        ></b-form-input>
                        <label for="fname4">Email 1</label>
                        <b-form-input type="email" size="sm" placeholder="Email here" v-model="data.email1" required />
                    </b-col>
                </b-row>
            </b-card-body>
            <hr class="my-4" />
            <div v-if="hasPermission">
                <div class="btn-grp text-right">
                    <b-button :disabled="loadingSave" variant="primary" class="px-4" type="submit">
                        <b-spinner small v-if="loadingSave"></b-spinner>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Customer',
    data: () => ({
        optionsCountry: [
            { value: null, text: 'Please select an option' },
            { value: 'Aruba', text: 'Aruba' },
            { value: 'US', text: 'Estados Unidos' },
        ],
        loadingCustomerTypes: true,
        loadingVerification: true,
        data: {
            reasonTypeId: 1,
            nationalID: '',
            firstName: '',
            middleName: '',
            lastName: '',
            businessId: '',
            businessLegalName: '',
            businessName: '',
            titleId: null,
            cityId: null,
            address1: '',
            address2: '',
            address3: '',
            phone1: '',
            phone2: '',
            email1: '',
            email1IsVerified: true,
            email2: '',
            email2IsVerified: true,
        },
        loan: null,
        loadingSave: false,
    }),
    computed: {
        ...mapGetters({
            customersTypes: 'general/customersTypes/getcustomersTypes',
            countries: 'general/countries/getCountries',
            cities: 'general/cities/getCities',
            authorizations: 'auth/getAuthorizations',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Add');
        },
    },
    methods: {
        async getCustomerTypes() {
            this.loadingCustomerTypes = true;
            await this.$store.dispatch('general/customersTypes/get_allCustomersTypes', { skip: 1, take: 9999 });
            this.loadingCustomerTypes = false;
        },
        async getData() {
            await this.$store.dispatch('general/countries/get_allCountries', { skip: 1, take: 9999 });
            await this.$store.dispatch('general/cities/get_allCities', { skip: 1, take: 9999 });
        },
        async saveData() {
            this.$bvToast.toast('This process is still being worked on', {
                title: 'Warning',
                variant: 'warning',
                solid: true,
            });

            // this.loadingSave = true;
            // const response = await this.$store.dispatch('customer/add_customer', this.data);
            // console.log(response);
            // this.$router.push('/customers');
            // this.loadingSave = false;
        },
    },
    mounted() {
        this.getCustomerTypes();
        this.getData();
    },
};
</script>
